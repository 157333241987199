<template>
    <vs-row class="w-100 mt-4">
      <vs-col vs-w="12">
        <p>
          <h5>Logo (links oben)</h5>
        Diese Grafik (optional) wird als Logo auf der Vorderseite des Ausweises (links oben) benutzt.
        </p>
        <div v-if="this.layout.header_picture_id">
          <div class="img-container w-25 p-3">
            <img class="image-preview img-fluid mt-4" :src="'data:image/png;base64, ' + headerPictureImgSrc" @click="showH=!showH" width="300" height="300">   
          </div>
        </div>
        <div v-else class="img-container w-25 p-3">
          <img class="img-fluid" @click="showH=!showH" src="/icons/upload-photo.svg"  alt="" width="300" height="300"/>
        </div>
        <cropUpload v-show="showH" field="img" @crop-success="cropSuccessHeader" @crop-upload-success="cropUploadSuccess"
          @crop-upload-fail="cropUploadFailHeader" @crop-delete="deleteCurrentLogoHeader" v-model="showH" :width="300" :height="300" :langExt="langExt" noCircle :params="params"
          :headers="headers"
          img-format="png" ref="uploader">
        </cropUpload>
        <hr width=88% size=2>
        <p>
          <h5>Logo (Hintergrund)</h5>
        Diese Grafik (optional) wird als Hintergrund auf der Vorderseite des Ausweises benutzt.
        </p>
        <div v-if="this.layout.background_picture_id">
          <div class="img-container w-25 p-3">
            <img class="image-preview img-fluid mt-4" :src="'data:image/png;base64, ' + backgroundImgSrc" @click="showB=!showB" width="300" height="300">   
          </div>
        </div>
        <div v-else class="img-container w-25 p-3">
          <img class="img-fluid" @click="showB=!showB" src="/icons/upload-photo.svg"  alt="" />
        </div>
        <cropUpload v-show="showB" field="img" @crop-success="cropSuccessBack" @crop-upload-success="cropUploadSuccess"
          @crop-upload-fail="cropUploadFailBack" @crop-delete="deleteCurrentLogoBack" v-model="showB" :width="300" :height="300" :langExt="langExt" noCircle :params="params"
          :headers="headers"
          img-format="png" ref="uploader">
        </cropUpload>
      </vs-col>
    </vs-row>
  
  </template>
  
  <script>
  import Vue from 'vue';
  import store from '@/store/index'
  import errorNotifier from "@/plugins/errorNotifier";
  import CropUpload from "@/plugins/mpcropupload/upload2.vue";
  
  import _ from 'lodash'
  
  export default Vue.extend({
      props: ["layout"],
  
      components: {
        CropUpload,
      },
      data(){
          return {
              // image64: '',
            backgroundImgSrc: null,
            headerPictureImgSrc: null,
  
            image:{
              size:'',
              height:'30',
              width:'30'
            },
            imageError:'',
            // CROP/UPLOAD
            
            showH: false,
            showB: false,
            params: {
              token: "123456798",
              name: "avatar",
            },
            headers: {
              smail: "*_~",
            },
            imgDataUrl: "",
            langExt: {
              titel: "Grafik bearbeiten",
              hint: "Klicken um Grafik auszuwählen oder Grafik direkt hierher ablegen",
              loading: "Wird hochgeladen...",
              noSupported: "Ihr Browser wird nicht unterstützt. Bitte verwenden Sie IE10+ oder einen anderen Browser.",
              uploadMessage: "Mit dem Upload bestätigen Sie, im Besitz der Nutzungsrechte für die Grafik zu sein.",
              uploadImgSize: "[Nur JPG-, PNG- oder SVG-Datei mit 300 x 300 Pixel und max. 1 MB Dateigröße]",
              success: "Erfolgreich hochgeladen",
              fail: "Hochladen fehlgeschlagen",
              preview: "Vorschau",
              btn: {
                off: "Abbrechen",
                close: "Schliessen",
                back: "Zurück",
                save: "Speichern",
                delete: "Löschen",
              },
              error: {
                onlyImg: "Nur Grafiken im Format: JPG,JPEG,PNG,GIF,SVG oder BMP",
                outOfSize: "Grafik zu groß, Maximum: ",
                lowestPx: "Grafik zu klein, Minimum: ",
              },
            },
          }
      },
  
  
      watch: {
          layout: {
              deep: true,
              async handler() {
                this.backgroundImgSrc = await this.$store.dispatch('core/getImage64', this.layout.background_picture_id)
                this.headerPictureImgSrc = await this.$store.dispatch('core/getImage64', this.layout.header_picture_id)
              }
          }
      },
  
      computed: {
        profileImage() {
              return store.getters['core/getImage']
        },
      },
  
      methods: {
          async deleteCurrentLogoHeader() {
              let status = await this.$store.dispatch('core/deleteImage', this.layout.header_picture_id )
              if (status != 200) {
                errorNotifier(status)
                return
              }
              this.layout.header_picture_id = null
          },
          async deleteCurrentLogoBack() {
              let status = await this.$store.dispatch('core/deleteImage', this.layout.background_picture_id )
              if (status != 200) {
                errorNotifier(status)
                return
              }
              this.layout.background_picture_id = null
          },
          cropSuccessBack(imgDataUrl, field) {
            this.imgDataUrl = imgDataUrl;
            let baseString = imgDataUrl.split(",")[1];
  
            this.$store.dispatch("core/createImage", baseString).then( resp => {
                      this.layout.background_picture_id = resp.data.id
                  })
            this.$refs.uploader.setStep(1);
          },
          cropSuccessHeader(imgDataUrl, field) {
            this.imgDataUrl = imgDataUrl;
            let baseString = imgDataUrl.split(",")[1];
  
            this.$store.dispatch("core/createImage", baseString).then( resp => {
                      this.layout.header_picture_id = resp.data.id
                  })
            this.$refs.uploader.setStep(1);
          },
  
          cropUploadSuccess(jsonData, field) {
          },
          cropUploadFailBack(status, field) {
            this.layout.background_picture_id = null
            this.$refs.uploader.setStep(1);
          },
          cropUploadFailHeader(status, field) {
            this.layout.header_picture_id = null
            this.$refs.uploader.setStep(1);
          },
      }
  });
  </script>
  <style scoped>
  .content-section{
      height:55vh;
      margin-top:40px;
  }
  </style>