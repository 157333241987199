<template>
  <div class="settings">

    <span class="con-slot-label">Layout-Vorlagen</span>
    <div class="table-container" style="overflow-y: auto;" >

      <table class="mt-4">
          <tbody>
              <tr v-for="layout in customerTemplates" :key="layout.id" @click="openLayout(layout.id)">
                  <td>{{layout.name}}</td>
              </tr>
          </tbody>
      </table>


    </div>

    <vs-row class="w-100">
      <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="12">
        <vs-button class="pop-btn organ-btn" color="primary" type="filled" icon="add"
            @click="open()">
            {{$t('Hinzufügen')}}</vs-button>
      </vs-col>
    </vs-row>

    <PopupLayout ref="PopupLayout" />

  </div>
</template>

<script>
import Vue from 'vue';
import PopupLayout from '@/components/layout/PopupLayout.vue'


export default Vue.extend({
  name: 'IDCardLayout',

  components: { PopupLayout },

  async mounted() {
    await this.$store.dispatch('layout/loadTemplates')
    await this.$store.dispatch('layout/loadCustomerTemplates')
  },

  computed: {
    layouts() {
      return this.$store.getters['layout/getLayouts']
    },
    templates() {
      return this.$store.getters["layout/getTemplates"]
    },
    customerTemplates() {
      return this.$store.getters["layout/getCustomerTemplates"]
    },
  },

  methods: {
    open() {
      const popup = this.$refs.PopupLayout 
      popup.open()
    },
    openLayout(id) {
      const popup = this.$refs.PopupLayout 
      popup.openEdit(id)
    }
  }
});
</script>
<style>

</style>