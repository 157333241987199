<template>
  <vs-popup :title="title" :active.sync="showPopup">
    <div class="popup p-2">

      <!-- MENU -->
      <vs-row class="menu">
        <div class="item me-2 p-2" :class="{'active': menu ==='general'}" @click="menu='general'">
          <span>{{$t('general')}}</span>
        </div>
        <div class="item me-2 p-2" :class="{'active': menu ==='fields'}" @click="menu='fields'">
          <span>{{$t('fields')}}</span>
        </div>
        <div class="item me-2 p-2" :class="{'active': menu ==='logo'}" @click="menu='logo'">
          <span>{{$t('logo')}}</span>
        </div>
        <div class="item me-2 p-2" :class="{'active': menu ==='frontside'}" @click="menu='frontside'">
          <span>{{$t('frontside')}}</span>
        </div>
        <div class="item me-2 p-2" :class="{'active': menu ==='backside'}" @click="menu='backside'">
          <span>{{$t('backside')}}</span>
        </div>
      </vs-row>


      
      <!-- POPUP BODY -->
      <vs-row class="content-section mt-4">
        <General v-show="menu==='general'" :layout="layout" :showTemplate="showTemplate" />
        <Fields v-show="menu==='fields'" :layout="layout" />
        <Logo v-show="menu==='logo'" :layout="layout"  />
        <FrontSide v-show="menu==='frontside'" :layout="layout" />
        <BackSide v-show="menu==='backside'" :layout="layout" />
      </vs-row>


      <!-- ACTION BUTTONS -->
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <vs-button v-if="isEdit" class="pop-btn" color="danger" type="filled" icon="delete" @click="deleteLayout(layout.id)"> {{$t('delete')}} </vs-button>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="6">
          <vs-button v-if="isEdit" class="pop-btn" color="primary" type="filled" icon="done" @click="updateLayout()"> {{$t('save')}}</vs-button>
          <vs-button v-else class="pop-btn" color="primary" type="filled" icon="done" @click="addLayout()"> {{$t('create')}}</vs-button>
          <vs-button class="pop-btn" color="primary" type="filled" icon="close" @click="showPopup=!showPopup"> {{$t('cancel')}} </vs-button>
        </vs-col>
      </vs-row>


      <!-- DEBUG MODE -->
      <vs-row vs-w="12">
        <div v-if="isDebugMode">
          <h1>LAYOUT</h1>
            {{layout}}
        </div>
      </vs-row>
      
    </div>
  </vs-popup>
</template>

<script>
  import Vue from "vue";

  import Fields from "./tabs/fields.vue";
  import FrontSide from "./tabs/frontside.vue";
  import BackSide from "./tabs/backside.vue";
  import General from "./tabs/general.vue";
  import Logo from "./tabs/logo.vue";
  import errorNotifier from "@/plugins/errorNotifier";

  import _ from "lodash";

  export default Vue.extend({
    // props:['organization'],
    components: { General, Fields,Logo, FrontSide, BackSide },

    async mounted() {
      // await this.$store.dispatch("auth/getUser")
      await this.$store.dispatch("layout/loadTemplates");
    },

    data() {
      return {
        menu: "general",
        showPopup: false,
        showTemplate: false,
        isEdit: false,
        fields: {},
        layout: {},
      };
    },

    computed: {
      title() {
        if (this.isEdit) {
          return this.$t("Layout bearbeiten > "+this.layout.name);
        }
        return this.$t("Layout anlegen");
      },
      templates() {
        return this.$store.getters["layout/getTemplates"];
      },
      user() {
        return this.$store.getters["auth/getUser"];
      },
    },

    methods: {
      initLayoutObj() {
        this.layout = {
          name: "",
          is_template: false,
          title: "",
          stylesheet: {
            header_background_color: "#ffffff",
            body_background_color: "#ffffff",
            header_text_color: "#000000",
            body_text_color: "#000000",
            fields_front: [
              [
                { key: "date_of_birth" },
                { key: "role"}
              ],
              [
                { key: "valid_until" },
                { key: "issuing_authority_name" },
              ]
            ],
            fields_back: [],
          },
          // header_picture_id: "",
          backside_text_additional: "",
          backside_footer_text: "",
          based_on_template_id: null,
          template_of_customer_id: null,
          // extra_1_caption: "",
          // extra_2_caption: "",
          // extra_3_caption: "",
          // extra_4_caption: "",
          // extra_1_single_value: "",
          // extra_2_single_value: "",
          // extra_3_single_value: "",
          // extra_4_single_value: "",
          backside_text: "",
        };
      },

      async openLayout(id) {
        // Load Layout
        await this.$store.dispatch("layout/loadLayout", id);
        const layout = this.$store.getters["layout/getLayout"];
        this.layout = _.cloneDeep(layout);

        this.showPopup = true;
        this.isEdit = true;
        this.showTemplate = false
      },

      async open() {
        this.initLayoutObj();
        // this.layout.template_of_customer_id = this.user.customer_id;
        await this.$store.dispatch("layout/loadTemplates");
        this.showPopup = true;
        this.isEdit = false;
        this.menu = "general";

        this.showTemplate = true
      },

      async openEdit(id) {
        await this.$store.dispatch("layout/loadLayout", id);
        const layout = this.$store.getters["layout/getLayout"];
        this.layout = _.cloneDeep(layout);

        this.showPopup = true;
        this.isEdit = true;
        this.menu = "fields";
        this.showTemplate = false
      },

      async addLayout() { 
        if (this.layout.name == '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte einen Namen angeben!",
          });
          return
        }
        // Create Orgnaization layout
        this.layout.is_template = true
        this.layout.template_of_customer_id = this.user.customer_id;
        let status = await this.$store.dispatch("layout/createLayout", this.layout);
        if (status === 201) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Layout wurde hinzugefügt",
          });
        } else {
          errorNotifier(status)
        }

        this.showPopup = false;
      },

      async updateLayout() {
        let status = await this.$store.dispatch("layout/updateLayout", this.layout);

        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Layout wurde gespeichert",
          });
        } else {
          errorNotifier(status)
        }

        this.showPopup = false;
      },
      async deleteLayout(id) {
        let status = await this.$store.dispatch("layout/deleteLayout", id);

        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Layout wurde gelöscht",
          });
        } else {
          errorNotifier(status)
        }
        this.showPopup = false;
      },
    },
  });
</script>
