<template>
  <vs-row>
    <vs-col class="mt-4" vs-w="12">
      <vs-input class="inputx date-input-md mt-3" :label-placeholder="$t('name')" 
          v-model="layout.name" />

      <vs-select v-if="showTemplate" label="Vorlagen" class="inputx date-input-md mt-3" 
        v-model="copyTemplate">

        <vs-select-item :key="template.id" @change="copyTemplateToLayout(template)"
            :value="template" :text="template.name"
            v-for="template in templates" />
      </vs-select>
    </vs-col>
  </vs-row>
</template>

<script>


import Vue from 'vue';
import _ from 'lodash'

export default Vue.extend({
  props: ["layout", "showTemplate"],

  data() {
    return {
      copyTemplate: {},
    }
  },

  watch: {
    copyTemplate(template) {
      this.layout.stylesheet = _.cloneDeep(template.stylesheet)
      this.layout.title = _.cloneDeep(template.title)
      //this.layout.name = _.cloneDeep(template.name)  -> Layout-Name nicht automatisch setzen
      this.layout.background_picture_id = _.cloneDeep(template.background_picture_id)
      this.layout.header_picture_id = _.cloneDeep(template.header_picture_id)
      this.layout.extra_1_caption = _.cloneDeep(template.extra_1_caption)
      this.layout.extra_2_caption = _.cloneDeep(template.extra_2_caption)
      this.layout.extra_3_caption = _.cloneDeep(template.extra_3_caption)
      this.layout.extra_4_caption = _.cloneDeep(template.extra_4_caption)
      this.layout.extra_5_caption = _.cloneDeep(template.extra_5_caption)
      this.layout.extra_6_caption = _.cloneDeep(template.extra_6_caption)
      this.layout.extra_1_single_value = _.cloneDeep(template.extra_1_single_value)
      this.layout.extra_2_single_value = _.cloneDeep(template.extra_2_single_value)
      this.layout.extra_3_single_value = _.cloneDeep(template.extra_3_single_value)
      this.layout.extra_4_single_value = _.cloneDeep(template.extra_4_single_value)
      this.layout.extra_5_single_value = _.cloneDeep(template.extra_5_single_value)
      this.layout.extra_6_single_value = _.cloneDeep(template.extra_6_single_value)
      this.layout.extra_1_values = _.cloneDeep(template.extra_1_values)
      this.layout.extra_2_values = _.cloneDeep(template.extra_2_values)
      this.layout.extra_3_values = _.cloneDeep(template.extra_3_values)
      this.layout.extra_4_values = _.cloneDeep(template.extra_4_values)
      this.layout.extra_5_values = _.cloneDeep(template.extra_5_values)
      this.layout.extra_6_values = _.cloneDeep(template.extra_6_values)
      this.layout.driver_license_class_values = _.cloneDeep(template.driver_license_class_values)
      this.layout.backside_text = _.cloneDeep(template.backside_text)
      this.layout.backside_text_additional = _.cloneDeep(template.backside_text_additional)
      this.layout.backside_footer_text = _.cloneDeep(template.backside_footer_text)


      if (this.showTemplate) {
        this.layout.based_on_template_id = template.id
      }
    },
  },

  computed: {
    templates:function() {
      let tmp= this.$store.getters['layout/getTemplates'];
      tmp = tmp.filter(u => {
        if (u.preconfigured_layout_id===null) {
          return true;
        }
      });
      return tmp;
    }
  },

});
</script>

